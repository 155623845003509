import React, { useState } from 'react';
import './index.css'; // Importiere die CSS-Datei

function CollapsibleCard({ title, children }) {
    // Zustand zum Verwalten, ob die Card eingeklappt ist oder nicht
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Funktion zum Umschalten des eingeklappten Zustands
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`card full-width-card ${isCollapsed ? 'collapsed' : ''}`}>
            <h3 onClick={toggleCollapse} className="card-title">
                {title}
            </h3>
            {/* Der Inhalt wird hier nur angezeigt, wenn isCollapsed false ist */}
            <div className="card-content" >
                {children}
            </div>
        </div>
    );
}

export default CollapsibleCard;