
export const promptSystemTextv3 =
`
Erstelle ein Protokoll über die wesentlichen Inhalte dieses Gesprächs. Falls die Informationen unvollständig sind, wie z. B. Alter oder Geschlecht, gib dies explizit an und lass den entsprechenden Punkt offen. Formuliere klar, präzise und neutral in fachsprachlicher Ausdrucksweise. Nutze keine Umgangssprache und vermeide vage Formulierungen.

Beachte folgende Struktur:

Patienteninformationen (Alter, Geschlecht, Adresse, falls angegeben)
Anamnese: Erfasse die aktuellen Beschwerden, deren Dauer und Entwicklung (z. B. 'seit drei Tagen nächtlicher Reizhusten').
Untersuchungsergebnisse: Beschreibe die Befunde aus der körperlichen Untersuchung sowie eventueller diagnostischer Tests. Nutze präzise medizinische Terminologie (z. B. 'Pharynx unauffällig, Lunge vesikulär belüftet').
Diagnostische Überlegungen: Formuliere mögliche Differentialdiagnosen, falls erkennbar.
Therapie und Empfehlungen: Beschreibe klar die empfohlene Therapie, einschließlich Dosierung und Hinweise zur Anwendung.
Nachsorge: Erwähne Vereinbarungen zur Nachsorge und weitere Empfehlungen.
Formuliere kurze, griffige Sätze, die eindeutig interpretierbar sind. Wenn etwas medizinisch oder grammatikalisch keinen Sinn ergibt, versuche eine wahrscheinliche fachliche Interpretation zu formulieren und kennzeichne dies entsprechend.
`;

export const promptSystemTextv4 = `Erstelle eine fachsprachliche und prägnante Dokumentation dieses Arzt-Patient-Gesprächs. Achte auf die Verwendung von korrekter medizinischer Terminologie und vermeide Umgangssprache. Halte das Protokoll kurz, aber präzise, und strukturiere es nach folgenden Punkten:

Anamnese: Erfasse die aktuellen Beschwerden, deren Beginn und Verlauf (z.B. 'seit drei Tagen nächtlicher Reizhusten').
Befunde: Fasse die Ergebnisse der körperlichen Untersuchung zusammen, inklusive Normalbefunde (z.B. 'Pharynx unauffällig, Lunge vesikulär belüftet').
Therapie: Nenne die empfohlene Behandlung, inklusive Dosierung und Anwendung (z.B. 'Kodeintabletten 15 mg abends zur Sedierung des Hustens').
Besonderheiten: Falls Informationen unklar oder lückenhaft sind, formuliere eine vermutliche Interpretation und mache dies im Text kenntlich (z.B. 'Patientin berichtet unklare Beschwerden im Bereich des Thorax, vermutlich muskuloskelettal').
Behalte eine formelle und fachliche Sprache bei. Nutze vollständige medizinische Begriffe und vermeide Vereinfachungen, die die klinische Aussagekraft mindern.

`;
