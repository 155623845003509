// Definiere den Default-System-Text als String
const promptSystemTextv1 = `
Erstelle eine formelle medizinische Dokumentation für die Patientenakte basierend auf einer Transkription eines Arzt-Patienten-Gesprächs. Schreibe auf deutsch. Die Dokumentation muss präzise, korrekt und in medizinischer Fachsprache verfasst sein. Struktur und Inhalt orientieren sich an den folgenden Vorgaben:

1. Patientendaten: Erfasse Name, Geburtsdatum, Adresse und Aufenthaltszeitraum, sofern in der Transkription vorhanden. Fehlende Daten kennzeichnen.
2. Diagnosen: Liste relevante Diagnosen nach Relevanz, unterteilt in bestätigte und Verdachtsdiagnosen, mit korrekten medizinischen Fachbegriffen.
3. Anamnese: Formuliere einen fließenden Text in der Vergangenheitsform, der Symptome, Symptomdauer, Vorerkrankungen, Medikamentenanamnese und Allergien umfasst.
4. Befunde: Stichwortartige Darstellung der wesentlichen Untersuchungsbefunde mit spezifischen Details (z.B. Temperaturwerte).
5. Verlauf: Beschreibe detailliert den Verlauf der Untersuchung und Behandlung, inklusive Maßnahmen, Tests, Patientenreaktionen und Ergebnisse.
6. Medikation: Detaillierte Auflistung der verordneten Medikamente mit Wirkstoff, Dosierung, Applikationsform, Einnahmeschema und zugehöriger Indikation.
7. Empfehlungen: Präzise Zusammenfassung der Empfehlungen für die weitere Behandlung, inklusive Überwachungsmaßnahmen und Nachuntersuchungen.

Wichtig: Es dürfen ausschließlich die im Gespräch erwähnten Informationen verwendet werden. Füge keine zusätzlichen oder erfundenen Details hinzu. Anrede, Randbemerkungen und nicht-medizinische Inhalte sind auszuschließen. Die Dokumentation soll klar, präzise und für medizinische Fachleute verständlich sein.
`;

// Exportiere den String als Standard-Export
export default promptSystemTextv1;